import { Component, Fragment } from "react";
import { hotel as gnHotel } from "gn-shared";
import SectionTitle from "../../elements/sectionTitle";
import { YieldApi } from "../../../api/yield";
import { AdminApi } from "../../../api/admin";
import "./HotelConfigStyles.css";
import InfoIcon from "@material-ui/icons/Info";
import {
  Button,
  Divider,
  MuiThemeProvider,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Grid,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import { State, rtTheme } from "./defs";
import { toast } from "react-toastify";
import moment from "moment";
import FormulaOverides from "./formulaOverides";
import { appStore } from "../../store";
import { ConfigService } from "../../services";
import withRouter from "../../withRouter";
import Popup from "../../../reusable-components/popup/popup";

class NewHotelConfig extends Component<{ activeHotelId: any; router: any }> {
  readonly state: State = {
    hotel: {
      fullName: "",
      name: "",
      userName: "NO DATA",
      password: "NO DATA",
    },
    deleteFormula: {
      hotelId: "",
      start: "",
      end: "",
      label: "",
      deleteLabel: "",
    },
    currentSetIndex: 1,
    displayUpdateRateWarningOnsave: false,
    warningDialogOpened: false,
    validateFullName: false,
    validateFullNameMsg: "",
    validateFormula: false,
    validateFormulaMsg: "",
    validateName: false,
    validateNameMsg: "",
    validateContractType: false,
    validateContractTypeMsg: "",
    validateTotalRoom: false,
    validateTotalRoomMsg: "",
    validateInventory: false,
    validateInventoryMsg: "",
    validateCmHotelCode: false,
    validateCmHotelCodeMsg: "",
    validatePmsHotelCode: false,
    validatePmsHotelCodeMsg: "",
    validateProviderKey: false,
    validateProviderKeyMsg: "",
    validatePmsUrlEndpoint: false,
    validatePmsUrlEndpointMsg: "",
    validateUserName: false,
    validateUserNameMsg: "",
    validateFelexTable: false,
    validateFelexTableMsg: "",
    validateOwsFelexTable: false,
    validateOwsFelexTableMsg: "",
    validateDefaultBar: false,
    validateDefaultBarMsg: "",
    validateMinRate: false,
    validateMinRateMsg: "",
    validateMaxRate: false,
    validateMaxRateMsg: "",
    validatePickupRange: false,
    validatePickupRangeMsg: "",
    validateOtaPickupRange: false,
    validateOtaPickupRangeMsg: "",
    setGuestline: false,
    onSave: false,
    hover: false,
    disableOTAPickup: false,
    isSyncOn: false,
    openRewardsPopup: false,
    rewardsPopupToBeOpen: false,
    originalHasRewardsValue: true,
  };

  hotel: any;

  constructor(props: any) {
    super(props);
    this.hotel = gnHotel(appStore.meta.config.hotels[this.props.activeHotelId]);

    this.state.hotel = JSON.parse(JSON.stringify(this.hotel));
    if (typeof this.state.hotel.cm.APISettings.OWSflexTable == "undefined") {
      this.state.hotel.cm.APISettings.flexTable = "";
    }
    if (typeof this.state.hotel.cm.APISettings.flexTable == "undefined") {
      this.state.hotel.cm.APISettings.flexTable = "";
    }
    if (typeof this.state.hotel.cm.channelConfig.ows.label == "undefined") {
      this.state.hotel.cm.channelConfig.ows.label = "";
    }
    if (typeof this.state.hotel.meta.pickupRange == "undefined") {
      this.state.hotel.meta.pickupRange = 0;
    }
    if (typeof this.state.hotel.meta.otaPickupRange == "undefined") {
      this.state.hotel.meta.otaPickupRange = 0;
    }
    this.state.isSyncOn = this.state.hotel.meta.isSyncOn;
    this.state.originalHasRewardsValue = this.state.hotel.meta.hasRewards;

    // PMS settings
    if (typeof this.state.hotel.pms == "undefined") {
      this.state.hotel.pms = {};
    }
    if (this.state.hotel.cm.id === "guestline") {
      this.state.hotel.pms.id = "guestline";
      this.state.hotel.pms.inventoryType = "live";
    } else {
      if (typeof this.state.hotel.pms.id == "undefined") {
        this.state.hotel.pms.id = "none";
        this.state.hotel.pms.inventoryType = "manual";
      }
    }
    if (
      this.state.hotel.pms.inventoryType === "live" &&
      this.state.hotel.pms.id !== "none"
    ) {
      this.state.hotel.meta.isPublishingInventory = false;
    }
    if (typeof this.state.hotel.pms.apiSettings == "undefined") {
      this.state.hotel.pms.apiSettings = {};
    }
    if (typeof this.state.hotel.pms.apiSettings.hotelCode == "undefined") {
      this.state.hotel.pms.apiSettings.hotelCode = "";
    }
    if (typeof this.state.hotel.pms.apiSettings.urlEndpoint == "undefined") {
      this.state.hotel.pms.apiSettings.urlEndpoint = "";
    }
  }

  componentDidMount(): void {
    if (this.state.hotel.cm.id === "guestline") {
      this.setState({ setGuestline: true });
    }
    if (
      this.state.hotel.cm.id !== "guestline" &&
      this.state.hotel.pms.id !== "hotsoft"
    ) {
      this.setState({ isSyncOn: false });

      this.setState({
        hotel: {
          ...this.state.hotel,
          meta: {
            ...this.state.hotel.meta,
            pickupRange: "0",
          },
        },
      });
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.state.isSyncOn !== prevState.isSyncOn) {
      if (this.state.isSyncOn === true) {
        this.setState({
          hotel: {
            ...this.state.hotel,
            meta: {
              ...this.state.hotel.meta,
              otaPickupRange: this.state.hotel.meta.pickupRange,
            },
          },
        });
      }
    }
  }

  onHotelUpdate = async (hotel: any) => {
    ConfigService.revanista.hotel.updateConfig(hotel.hotelId, this.props);
  };

  saveConfig = async () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const pattern = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;
    this.setState({ onSave: true });
    let OWSflexTable =
      typeof this.state.hotel.cm.APISettings.OWSflexTable != "undefined"
        ? this.state.hotel.cm.APISettings.OWSflexTable
        : "";
    if (
      typeof this.state.hotel.cm.channelConfig.ows.seasonnalFormula ==
      "undefined"
    ) {
      this.state.hotel.cm.channelConfig.ows["seasonnalFormula"] = false;
      this.state.hotel.cm.channelConfig.ows["SeasonnalConfig"] = {
        formulas: [],
      };
    }

    if (this.state.hotel.cm.id !== "guestline") {
      this.setState({});
    }

    if (
      !this.state.hotel.info.fullName ||
      !this.state.hotel.cm.channelConfig.ows.formula ||
      !this.state.hotel.info.name ||
      this.state.hotel.meta.isPublishingInventory == "undefined" ||
      !this.state.hotel.info.level ||
      this.state.hotel.info.level == "undefined" ||
      !this.state.hotel.meta.totalRooms ||
      !this.state.hotel.meta.defaultBar ||
      !(this.state.hotel.meta.totalRooms > 0) ||
      !this.state.hotel.info.fullName.match(/^[a-zA-Z\s'&]+$/) ||
      !this.state.hotel.info.name.match(/^[a-zA-Z\s]+$/) ||
      (!OWSflexTable.match(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/) &&
        this.state.setGuestline === true) ||
      (!this.state.hotel.cm.APISettings.flexTable.match(
        /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/,
      ) &&
        this.state.setGuestline === true) ||
      (!this.state.hotel.cm.APISettings.flexTable &&
        this.state.setGuestline === true) ||
      (!OWSflexTable && this.state.setGuestline === true) ||
      this.state.hotel.meta.pickupRange === null ||
      this.state.hotel.meta.pickupRange === "" ||
      this.state.hotel.meta.otaPickupRange === null ||
      this.state.hotel.meta.otaPickupRange === ""
    ) {
      if (
        !this.state.hotel.cm.APISettings.flexTable &&
        this.state.setGuestline === true
      ) {
        this.setState({ validateFelexTable: true });
        this.setState({
          validateFelexTableMsg: "Mandatory field",
        });
      } else if (
        !this.state.hotel.cm.APISettings.flexTable.match(
          /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/,
        ) &&
        this.state.setGuestline === true
      ) {
        this.setState({ validateFelexTable: true });
        this.setState({
          validateFelexTableMsg: "Only Alphabets, numbers and spaces allowed.",
        });
      } else {
        this.setState({ validateFelexTable: false });
        this.setState({ validateFelexTableMsg: "" });
      }

      if (!OWSflexTable && this.state.setGuestline === true) {
        this.setState({ validateOwsFelexTable: true });
        this.setState({
          validateOwsFelexTableMsg: "Mandatory field",
        });
      } else if (
        !OWSflexTable.match(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/) &&
        this.state.setGuestline === true
      ) {
        this.setState({ validateOwsFelexTable: true });
        this.setState({
          validateOwsFelexTableMsg:
            "Only Alphabets, numbers and spaces allowed.",
        });
      } else {
        this.setState({ validateOwsFelexTable: false });
        this.setState({ validateOwsFelexTableMsg: "" });
      }

      if (!this.state.hotel.info.fullName) {
        this.setState({ validateFullName: true });
        this.setState({
          validateFullNameMsg: "Mandatory field",
        });
      } else if (!this.state.hotel.info.fullName.match(/^[a-zA-Z\s'&]+$/)) {
        this.setState({ validateFullName: true });
        this.setState({
          validateFullNameMsg:
            "Only alphabets, spaces, and following special characters allowed:  &  '",
        });
      } else {
        this.setState({ validateFullName: false });
        this.setState({ validateFullNameMsg: "" });
      }

      if (!this.state.hotel.info.name) {
        this.setState({ validateName: true });
        this.setState({ validateNameMsg: "Mandatory field" });
      } else if (!this.state.hotel.info.name.match(/^[a-zA-Z\s]+$/)) {
        this.setState({ validateName: true });
        this.setState({
          validateNameMsg: "Only alphabets and spaces allowed",
        });
      } else {
        this.setState({ validateName: false });
        this.setState({ validateNameMsg: "" });
      }

      if (
        !this.state.hotel.meta.isPublishingInventory ||
        this.state.hotel.meta.isPublishingInventory === "undefined"
      ) {
        this.setState({ validateInventory: true });
        this.setState({ validateInventoryMsg: "Mandatory field" });
      } else {
        this.setState({ validateInventory: false });
        this.setState({ validateInventoryMsg: "" });
      }

      if (
        !this.state.hotel.info.level ||
        this.state.hotel.info.level === "undefined"
      ) {
        this.setState({ validateContractType: true });
        this.setState({
          validateContractTypeMsg: "Mandatory field",
        });
      } else {
        this.setState({ validateContractType: false });
        this.setState({ validateContractTypeMsg: "" });
      }

      if (!this.state.hotel.meta.totalRooms) {
        this.setState({ validateTotalRoom: true });
        this.setState({
          validateTotalRoomMsg: "Mandatory field",
        });
      } else if (this.state.hotel.meta.totalRooms < 0) {
        this.setState({ validateTotalRoom: true });
        this.setState({
          validateTotalRoomMsg: "Negative number not allowed",
        });
      } else {
        this.setState({ validateTotalRoom: false });
        this.setState({ validateTotalRoomMsg: "" });
      }

      if (!this.state.hotel.meta.defaultBar) {
        this.setState({ validateDefaultBar: true });
        this.setState({
          validateDefaultBarMsg: "Mandatory field",
        });
      } else {
        this.setState({ validateDefaultBar: false });
        this.setState({ validateDefaultBarMsg: "" });
      }

      if (!this.state.hotel.meta.pickupRange) {
        this.setState({ validatePickupRange: true });
        this.setState({
          validatePickupRangeMsg: "Mandatory field",
        });
      } else {
        this.setState({ validatePickupRange: false });
        this.setState({ validatePickupRangeMsg: "" });
      }
      if (!this.state.hotel.meta.otaPickupRange) {
        console.log("ota pickup value", this.state.hotel.meta.otaPickupRange);

        this.setState({ validateOtaPickupRange: true });
        this.setState({
          validateOtaPickupRangeMsg: "Mandatory field",
        });
      } else {
        this.setState({ validateOtaPickupRange: false });
        this.setState({ validateOtaPickupRangeMsg: "" });
      }
      toast.error("Please check highlighted field(s)");
      return;
    } else if (this.state.hotel.meta.pickupRange < 0) {
      this.setState({ validatePickupRange: true });
      this.setState({
        validatePickupRangeMsg: "Negative number not allowed",
      });
      toast.error("Please check the highlited field(s)");
      return;
    } else if (this.state.hotel.meta.otaPickupRange < 0) {
      this.setState({ validateOtaPickupRange: true });
      this.setState({
        validateOtaPickupRangeMsg: "Negative number not allowed",
      });
      toast.error("Please check the highlited field(s)");
      return;
    } else if (
      !pattern.test(this.state.hotel.cm.APISettings.hotelId) ||
      !pattern.test(this.state.hotel.cm.credentials.userId)
    ) {
      toast.error("Special characters not allowed");
      return;
    } else if (this.state.hotel.meta.totalRooms.length >= 20) {
      toast.error("Maximum length exceeded");
      return;
    } else if (this.state.hotel.meta.defaultBar < 0) {
      toast.error("Negative number not allowed");
      return;
    } else if (this.state.hotel.meta.defaultBar > 999) {
      toast.error("Default base rate can't be greater then 999");
      return;
    } else {
      if (this.state.rewardsPopupToBeOpen === true) {
        this.setState({ openRewardsPopup: true });
      } else {
        this.saveConfigAfterValidation();
      }
    }
  };

  saveConfigAfterValidation = async () => {
    this.setState({ openRewardsPopup: false });
    const hotel = this.state.hotel;
    const deleteFormula = this.state.deleteFormula;

    this.setState({ hotel });
    this.onHotelUpdate(hotel);
    let warningDialogOpened = this.state.displayUpdateRateWarningOnsave;
    //Reorder Override Formula
    if (hotel.cm.channelConfig.ows.SeasonnalConfig.formulas.length > 0) {
      hotel.cm.channelConfig.ows.SeasonnalConfig.formulas.forEach(
        (item: any) => {
          item.order = Number(
            item.formula.replace(/BR/g, "").replace(/\s/g, ""),
          );
        },
      );
      hotel.cm.channelConfig.ows.SeasonnalConfig.formulas.sort(
        (a: any, b: any) => parseFloat(b.order) - parseFloat(a.order),
      );
    }

    let updateConfigPromise = toast.promise(
      YieldApi.updateHotelConfig({
        hotel: this.state.hotel,
        start: moment().format("YYYY-MM-DD"),
      }),
      {
        pending: "Saving hotel config...",
        error: "Error saving hotel config",
        success: "Hotel config saved",
      },
    );
    updateConfigPromise.catch(() => {
      return;
    });

    if (warningDialogOpened || deleteFormula.deleteLabel) {
      if (deleteFormula.deleteLabel) {
        toast.promise(
          YieldApi.writeOWSOverrideSQS(deleteFormula).then(() => {
            this.setState({
              deleteFormula: {
                hotelId: "",
                start: "",
                end: "",
                label: "",
                deleteLabel: "",
              },
            });
          }),
          {
            pending: "Deleting OWS Formula override...",
            error: "Error deleting OWS Formula override",
            success: "OWS Formula override deleted",
          },
        );
      } else {
        if (warningDialogOpened) {
          let formulaLabel;
          if (typeof hotel.cm.channelConfig.ows.label != "undefined") {
            if (hotel.cm.channelConfig.ows.label != "") {
              formulaLabel = hotel.cm.channelConfig.ows.label;
            } else {
              formulaLabel = "Default";
            }
          } else {
            formulaLabel = "Default";
          }
          let updateDefaultFormula = {
            hotelId: this.state.hotel.hotelId,
            start: moment().format("YYYY-MM-DD"),
            end: moment().add("2", "year").format("YYYY-MM-DD"),
            label: formulaLabel,
          };

          toast.promise(YieldApi.writeOWSOverrideSQS(updateDefaultFormula), {
            pending: "Updating Default OWS differential...",
            error: "Error updating Default OWS differential",
            success: "Default OWS differential updated",
          });
        }
      }
    }
    this.setState({
      originalHasRewardsValue: this.state.hotel.meta.hasRewards,
    });
  };

  cancelChanges = async () => {
    this.setState({
      deleteFormula: {
        hotelId: "",
        start: "",
        end: "",
        label: "",
        deleteLabel: "",
      },
    });

    try {
      await AdminApi.getHotelConfig(this.state.hotel.hotelId).then(
        (res: any) => {
          this.setState({
            hotel: JSON.parse(JSON.stringify(res)),
            currentSetIndex: this.state.currentSetIndex + 1,
          });
        },
      );
    } catch (error) {
      console.log(error);
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    this.setState({
      validateFullName: false,
      validateFullNameMsg: "",
      validateName: false,
      validateNameMsg: "",
      validateContractType: false,
      validateContractTypeMsg: "",
      validateTotalRoom: false,
      validateTotalRoomMsg: "",
      validateInventory: false,
      validateInventoryMsg: "",
      validateCmHotelCode: false,
      validateCmHotelCodeMsg: "",
      validatePmsHotelCode: false,
      validatePmsHotelCodeMsg: "",
      validateUserName: false,
      validateUserNameMsg: "",
      validateFelexTable: false,
      validateFelexTableMsg: "",
      validateOwsFelexTable: false,
      validateOwsFelexTableMsg: "",
    });
  };
  closewarningDialog = () => {
    let warningDialogOpened = false;
    this.setState({ warningDialogOpened });
  };

  onBlurOfInputFields = (event: any) => {
    let fieldName = event.target.name;
    let value = event.target.value;
    switch (fieldName) {
      case "fullName":
        if (!value) {
          this.setState({ validateFullName: true });
          this.setState({
            validateFullNameMsg: "Mandatory field",
          });
        } else if (!value.match(/^[a-zA-Z\s'&]+$/)) {
          this.setState({ validateFullName: true });
          this.setState({
            validateFullNameMsg:
              "Only alphabets, spaces, and following special characters allowed:  &  '",
          });
        } else {
          this.setState({ validateFullName: false });
          this.setState({ validateFullNameMsg: "" });
        }
        break;
      case "name":
        if (!value) {
          this.setState({ validateName: true });
          this.setState({ validateNameMsg: "Mandatory field" });
        } else if (!value.match(/^[a-zA-Z\s]+$/)) {
          this.setState({ validateName: true });
          this.setState({
            validateNameMsg: "Only alphabets and spaces allowed",
          });
        } else {
          this.setState({ validateName: false });
          this.setState({ validateNameMsg: "" });
        }
        break;
      case "level":
        if (!value) {
          this.setState({ validateContractType: true });
          this.setState({
            validateContractTypeMsg: "Mandatory field",
          });
        } else {
          this.setState({ validateContractType: false });
          this.setState({ validateContractTypeMsg: "" });
        }
        break;
      case "totalRooms":
        if (!value) {
          this.setState({ validateTotalRoom: true });
          this.setState({ validateTotalRoomMsg: "Mandatory field" });
        } else if (this.state.hotel.meta.totalRooms.length >= 20) {
          this.setState({ validateTotalRoom: true });
          this.setState({ validateTotalRoomMsg: "Length Exceeded" });
        } else if (this.state.hotel.meta.totalRooms < 0) {
          this.setState({ validateTotalRoom: true });
          this.setState({
            validateTotalRoomMsg: "Negative number not allowed",
          });
        } else {
          this.setState({ validateTotalRoom: false });
          this.setState({ validateTotalRoomMsg: "" });
        }
        break;
      case "isPublishingInventory":
        if (!value) {
          this.setState({ validateInventory: true });
          this.setState({ validateInventoryMsg: "Mandatory field" });
        } else {
          this.setState({ validateInventory: false });
          this.setState({ validateInventoryMsg: "" });
        }
        break;
      case "pmsProvider":
        if (!value) {
          this.setState({ validateProviderKey: true });
          this.setState({
            validateProviderKeyMsg: "Mandatory field",
          });
        } else {
          this.setState({ validateProviderKey: false });
          this.setState({ validateProviderKeyMsg: "" });
        }
        break;
      case "pmsHotelCode":
        if (!value) {
          this.setState({ validatePmsHotelCode: false });
          this.setState({ validatePmsHotelCodeMsg: "" });
        } else if (!value.match(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/)) {
          this.setState({ validatePmsHotelCode: true });
          this.setState({
            validatePmsHotelCodeMsg:
              "Only Alphabets, numbers and spaces allowed.",
          });
        } else {
          this.setState({ validatePmsHotelCodeMsg: false });
          this.setState({ validatePmsHotelCodeMsg: "" });
        }
        break;
      case "urlEndpoint":
        if (!value) {
          this.setState({ validatePmsUrlEndpoint: false });
          this.setState({ validatePmsUrlEndpoint: "" });
        } else {
          this.setState({ validatePmsUrlEndpoint: false });
          this.setState({ validatePmsUrlEndpoint: "" });
        }
        break;
      case "OWSFormula":
        if (!value) {
          this.setState({ validateFormula: true });
          this.setState({ validateFormulaMsg: "Mandatory field" });
        } else {
          this.setState({ validateFormula: false });
          this.setState({ validateFormulaMsg: "" });
        }
        break;
      case "cmHotelCode":
        if (!value) {
          this.setState({ validateCmHotelCode: false });
          this.setState({ validateCmHotelCodeMsg: "" });
        } else if (!value.match(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/)) {
          this.setState({ validateCmHotelCode: true });
          this.setState({
            validateCmHotelCodeMsg:
              "Only Alphabets, numbers and spaces allowed.",
          });
        } else {
          this.setState({ validateCmHotelCode: false });
          this.setState({ validateCmHotelCodeMsg: "" });
        }
        break;
      case "cmUserId":
        if (!value) {
          this.setState({ validateUserName: false });
          this.setState({ validateUserNameMsg: "" });
        } else if (!value.match(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/)) {
          this.setState({ validateUserName: true });
          this.setState({
            validateUserNameMsg: "Only Alphabets, numbers and spaces allowed.",
          });
        } else {
          this.setState({ validateUserName: false });
          this.setState({ validateUserNameMsg: "" });
        }
        break;
      case "flexTable":
        if (!value) {
          this.setState({ validateFelexTable: true });
          this.setState({
            validateFelexTableMsg: "Mandatory field",
          });
        } else if (!value.match(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/)) {
          this.setState({ validateFelexTable: true });
          this.setState({
            validateFelexTableMsg:
              "Only Alphabets, numbers and spaces allowed.",
          });
        } else {
          this.setState({ validateFelexTable: false });
          this.setState({ validateFelexTableMsg: "" });
        }
        break;
      case "OWSflexTable":
        if (!value) {
          this.setState({ validateOwsFelexTable: true });
          this.setState({
            validateOwsFelexTableMsg: "Mandatory field",
          });
        } else if (!value.match(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/)) {
          this.setState({ validateOwsFelexTable: true });
          this.setState({
            validateOwsFelexTableMsg:
              "Only Alphabets, numbers and spaces allowed.",
          });
        } else {
          this.setState({ validateOwsFelexTable: false });
          this.setState({ validateOwsFelexTableMsg: "" });
        }
        break;
      case "defaultBar":
        if (!value) {
          this.setState({ validateDefaultBar: true });
          this.setState({
            validateDefaultBarMsg: "Mandatory field",
          });
        } else if (value < 0) {
          this.setState({ validateDefaultBar: true });
          this.setState({
            validateDefaultBarMsg: "Negative number not allowed",
          });
        } else if (value > 999) {
          this.setState({ validateDefaultBar: true });
          this.setState({
            validateDefaultBarMsg:
              "Default base rate can't be greater then 999",
          });
        } else {
          this.setState({ validateDefaultBar: false });
          this.setState({ validateDefaultBarMsg: "" });
        }
        break;
      case "pickupRange":
        if (!value) {
          this.setState({ validatePickupRange: true });
          this.setState({
            validatePickupRangeMsg: "Mandatory field",
          });
        } else if (this.state.hotel.meta.pickupRange.length >= 20) {
          this.setState({ validatePickupRange: true });
          this.setState({ validatePickupRangeMsg: "Length Exceeded" });
        } else if (this.state.hotel.meta.pickupRange < 0) {
          this.setState({ validatePickupRange: true });
          this.setState({
            validatePickupRangeMsg: "Negative number not allowed",
          });
        } else {
          this.setState({ validatePickupRange: false });
          this.setState({ validatePickupRangeMsg: "" });
        }
        break;
      case "otaPickupRange":
        if (!value) {
          this.setState({ validateOtaPickupRange: true });
          this.setState({
            validateOtaPickupRangeMsg: "Mandatory field",
          });
        } else if (this.state.hotel.meta.otaPickupRange.length >= 20) {
          this.setState({ validateOtaPickupRange: true });
          this.setState({ validateOtaPickupRangeMsg: "Length Exceeded" });
        } else if (this.state.hotel.meta.otaPickupRange < 0) {
          this.setState({ validateOtaPickupRange: true });
          this.setState({
            validateOtaPickupRangeMsg: "Negative number not allowed",
          });
        } else {
          this.setState({ validateOtaPickupRange: false });
          this.setState({ validateOtaPickupRangeMsg: "" });
        }
        break;
      default:
        this.setState({ validateFullName: false });
        this.setState({ validateFullNameMsg: "" });
    }
  };

  handleChange = (event: any) => {
    let fieldName = event.target.name;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    let hotel = this.state.hotel;
    let displayUpdateRateWarningOnsave = false;

    switch (fieldName) {
      case "seasonnalOWS":
        hotel.cm.channelConfig.ows.seasonnalFormula = value;
        hotel.cm.channelConfig.ows.SeasonnalConfig = hotel.cm.channelConfig.ows
          .SeasonnalConfig || {
          formulas: [],
        };
        break;
      case "OWSLabel":
        hotel.cm.channelConfig.ows.label = value;
        displayUpdateRateWarningOnsave = true;
        break;
      case "OWSFormula":
        hotel.cm.channelConfig.ows.formula = value;
        displayUpdateRateWarningOnsave = true;
        break;
      case "fullName":
        hotel.info.fullName = value;
        break;
      case "name":
        hotel.info.name = value;
        break;
      case "level":
        hotel.info.level = value;
        break;
      case "totalRooms":
        if (value.length >= 20) {
          this.setState({ validateTotalRoom: true });
          this.setState({ validateTotalRoomMsg: "Length Exceeded" });
        } else {
          this.setState({ validateTotalRoom: false });
          this.setState({ validateTotalRoomMsg: "" });
        }
        hotel.meta.totalRooms = value;
        break;
      case "cmUserId":
        hotel.cm.credentials.userId = value;
        break;
      case "isPublishingInventory":
        hotel.meta.isPublishingInventory = value;
        break;
      case "cmHotelCode":
        hotel.cm.APISettings.hotelId = value;
        break;
      case "pmsProvider":
        hotel.pms.id = value;
        if (value === "guestline") {
          hotel.pms.inventoryType = "live";
        }
        break;
      case "pmsHotelCode":
        hotel.pms.apiSettings.hotelCode = value;
        break;
      case "urlEndpoint":
        hotel.pms.apiSettings.urlEndpoint = value;
        break;
      case "inventoryType":
        hotel.pms.inventoryType = value;
        if (
          this.state.hotel.pms.inventoryType === "live" &&
          this.state.hotel.pms.id !== "none"
        ) {
          hotel.meta.isPublishingInventory = false;
        }
        break;
      case "pickupRange":
        hotel.meta.pickupRange = value;
        if (this.state.isSyncOn === true) {
          hotel.meta.otaPickupRange = value;
        }
        break;
      case "otaPickupRange":
        hotel.meta.otaPickupRange = value;
        break;
      case "UsingRewards":
        let newValue = value === "true" ? true : false;
        if (
          this.state.originalHasRewardsValue !== newValue &&
          newValue === false
        ) {
          this.setState({ rewardsPopupToBeOpen: true });
        }
        hotel.meta.hasRewards = newValue;
        break;
      case "isSyncOn":
        this.setState({
          isSyncOn: value,
          disableOTAPickup: value,
        });
        if (value === true) {
          this.setState({
            validateOtaPickupRange: false,
            validateOtaPickupRangeMsg: "",
          });
        }
        hotel.meta.isSyncOn = value;
        break;
      case "flexTable":
        hotel.cm.APISettings.flexTable = value;
        break;
      case "OWSflexTable":
        hotel.cm.APISettings.OWSflexTable = value;
        break;
      case "BRflexTableRate":
        hotel.cm.APISettings.BRflexTableRate = value;
        break;

      case "OWSflexTableRate":
        hotel.cm.APISettings.OWSflexTableRate = value;
        break;
      case "Currency":
        hotel.info.currencyCode = value;
        break;
      case "isRateMatrix":
        hotel.suggestionParams.isEnabled = value;
        break;
      case "defaultBar":
        hotel.meta.defaultBar = value;

        if (!value) {
          this.setState({ validateDefaultBar: true });
          this.setState({
            validateDefaultBarMsg: "Mandatory field",
          });
        } else if (value < 0) {
          this.setState({ validateDefaultBar: true });
          this.setState({
            validateDefaultBarMsg: "Negative number not allowed",
          });
        } else if (value > 999) {
          this.setState({ validateDefaultBar: true });
          this.setState({
            validateDefaultBarMsg:
              "Default base rate can't be greater then 999",
          });
        } else {
          this.setState({ validateDefaultBar: false });
          this.setState({ validateDefaultBarMsg: "" });
        }
        break;
      case "channelManager":
        hotel.cm.id = value;
        if (value === "guestline") {
          hotel.pms.id = "guestline";
          hotel.pms.inventoryType = "live";
        } else if (hotel.pms.id === "guestline") {
          hotel.pms.id = "none";
          hotel.pms.inventoryType = "manual";
        }
        break;
    }

    this.setState({ hotel, displayUpdateRateWarningOnsave });
  };

  handleOnMouseOver = () => {
    this.setState({ hover: true });
  };

  handleOnMouseOut = () => {
    this.setState({ hover: false });
  };

  handleOnCheckboxCheck = () => {
    this.setState({
      isSyncOn: !this.state.isSyncOn,
      disableOTAPickup: !this.state.disableOTAPickup,
    });
  };

  closeRewardsPopup = () => {
    this.setState({ openRewardsPopup: false });
  };

  render() {
    let date = "2024-04-15";
    let onboardDate = this.hotel.hotelOnboardDate;
    var a = onboardDate ? onboardDate : moment(date);
    var b = moment(moment().format("YYYY-MM-DD"));

    let diff = b.diff(a, "days");

    if (this.state.hotel.meta.otaPickupRange > diff) {
      console.log("is greater", diff);
    } else {
      console.log("is lesss", diff);
    }

    console.log(
      "appStore.meta._user",
      appStore.meta._user.permissions.admin[0],
    );

    return (
      <MuiThemeProvider theme={rtTheme}>
        <div className="content">
          {appStore.meta._user.permissions.admin[0] !== "*" ? (
            <p
              style={{ color: "red", fontWeight: "bold", textAlign: "center" }}
            >
              This page is admin locked. Please contact us should you need
              something changed.
            </p>
          ) : (
            <></>
          )}
          <SectionTitle {...{ value: "Global Settings" }} />
          <br />
          <div style={{ flexGrow: "1" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  // className="input-field"
                  style={{
                    width: "100%",
                    backgroundColor:
                      appStore.meta._user.permissions.admin[0] !== "*"
                        ? "#D3D3D3"
                        : "none",
                  }}
                  fullWidth
                  id="outlined-basic"
                  label="Hotel Name"
                  variant="outlined"
                  name="fullName"
                  onChange={this.handleChange}
                  value={this.state.hotel.info.fullName}
                  onBlur={this.onBlurOfInputFields.bind(this)}
                  error={this.state.validateFullName}
                  helperText={this.state.validateFullNameMsg}
                  inputProps={{
                    maxLength: 40,
                    readOnly:
                      appStore.meta._user.permissions.admin[0] !== "*"
                        ? true
                        : false,
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  // className="input-field"
                  style={{
                    width: "100%",
                    backgroundColor:
                      appStore.meta._user.permissions.admin[0] !== "*"
                        ? "#D3D3D3"
                        : "none",
                  }}
                  fullWidth
                  id="outlined-basic"
                  label="Hotel Short Name"
                  variant="outlined"
                  name="name"
                  onChange={this.handleChange}
                  value={this.state.hotel.info.name}
                  onBlur={this.onBlurOfInputFields.bind(this)}
                  error={this.state.validateName}
                  helperText={this.state.validateNameMsg}
                  inputProps={{
                    maxLength: 20,
                    readOnly:
                      appStore.meta._user.permissions.admin[0] !== "*"
                        ? true
                        : false,
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  style={{ width: "100%", backgroundColor: "#D3D3D3" }}
                  fullWidth
                  id="outlined-basic"
                  label="Hotel ID"
                  variant="outlined"
                  name="hotelId"
                  onChange={this.handleChange}
                  value={this.state.hotel.hotelId}
                  inputProps={{ readOnly: true }}
                />
              </Grid>

              <br />

              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  value={this.state.hotel.info.level}
                  style={{
                    width: "100%",
                    backgroundColor:
                      appStore.meta._user.permissions.admin[0] !== "*"
                        ? "#D3D3D3"
                        : "none",
                  }}
                  fullWidth
                  select
                  label="Contract Type"
                  variant="outlined"
                  name="level"
                  onChange={this.handleChange}
                  onBlur={this.onBlurOfInputFields.bind(this)}
                  error={this.state.validateContractType}
                  helperText={this.state.validateContractTypeMsg}
                  inputProps={{
                    readOnly:
                      appStore.meta._user.permissions.admin[0] !== "*"
                        ? true
                        : false,
                  }}
                >
                  <option value="Pro">Pro</option>
                  <option value="Plus">Plus</option>
                  <option value="Assist">Assist</option>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  style={{
                    width: "100%",
                    backgroundColor:
                      appStore.meta._user.permissions.admin[0] !== "*"
                        ? "#D3D3D3"
                        : "none",
                  }}
                  fullWidth
                  id="outlined-basic"
                  label="Total Number of Rooms"
                  variant="outlined"
                  name="totalRooms"
                  type="number"
                  onChange={this.handleChange}
                  value={this.state.hotel.meta.totalRooms}
                  onBlur={this.onBlurOfInputFields.bind(this)}
                  error={this.state.validateTotalRoom}
                  helperText={this.state.validateTotalRoomMsg}
                  inputProps={{
                    maxLength: 20,
                    min: 0,
                    max: 99999999999999999999,
                    readOnly:
                      appStore.meta._user.permissions.admin[0] !== "*"
                        ? true
                        : false,
                  }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  value={this.state.hotel.suggestionParams.isEnabled}
                  style={{
                    width: "100%",
                    backgroundColor:
                      appStore.meta._user.permissions.admin[0] !== "*"
                        ? "#D3D3D3"
                        : "none",
                  }}
                  fullWidth
                  id="outlined"
                  select
                  label="Rate Matrix"
                  variant="outlined"
                  name="isRateMatrix"
                  onChange={this.handleChange}
                  inputProps={{
                    readOnly:
                      appStore.meta._user.permissions.admin[0] !== "*"
                        ? true
                        : false,
                  }}
                >
                  <option value="true">Enabled</option>
                  <option value="false">Disabled</option>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  value={this.state.hotel.meta.hasRewards}
                  style={{
                    width: "100%",
                    backgroundColor:
                      appStore.meta._user.permissions.admin[0] !== "*"
                        ? "#D3D3D3"
                        : "none",
                  }}
                  fullWidth
                  select
                  label="Rewards Site Function"
                  variant="outlined"
                  name="UsingRewards"
                  onChange={this.handleChange}
                  onBlur={this.onBlurOfInputFields.bind(this)}
                  inputProps={{
                    readOnly:
                      appStore.meta._user.permissions.admin[0] !== "*"
                        ? true
                        : false,
                  }}
                >
                  <option value="true">Enabled</option>
                  <option value="false">Disabled</option>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  defaultValue={this.state.hotel.info.currencyCode}
                  style={{
                    width: "100%",
                    backgroundColor:
                      appStore.meta._user.permissions.admin[0] !== "*"
                        ? "#D3D3D3"
                        : "none",
                  }}
                  fullWidth
                  select
                  label="Currency"
                  variant="outlined"
                  name="Currency"
                  onChange={this.handleChange}
                  inputProps={{
                    readOnly:
                      appStore.meta._user.permissions.admin[0] !== "*"
                        ? true
                        : false,
                  }}
                >
                  <option value="EUR">EUR</option>
                  <option value="GBP">GBP</option>
                </TextField>
              </Grid>
            </Grid>
          </div>
          <br />

          <SectionTitle {...{ value: "Default Config" }} />
          <br />
          <div style={{ flexGrow: "1" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  // className="input-field"
                  style={{
                    width: "100%",
                    backgroundColor:
                      appStore.meta._user.permissions.admin[0] !== "*"
                        ? "#D3D3D3"
                        : "none",
                  }}
                  fullWidth
                  id="outlined-basic"
                  label="Default Base Rate"
                  variant="outlined"
                  type="number"
                  name="defaultBar"
                  onChange={this.handleChange}
                  value={this.state.hotel.meta.defaultBar}
                  onBlur={this.onBlurOfInputFields.bind(this)}
                  error={this.state.validateDefaultBar}
                  helperText={this.state.validateDefaultBarMsg}
                  inputProps={{
                    maxLength: 20,
                    readOnly:
                      appStore.meta._user.permissions.admin[0] !== "*"
                        ? true
                        : false,
                  }}
                  required
                />
              </Grid>
            </Grid>

            <br />

            <Grid container spacing={3}>
              {this.state.hotel.cm.id === "guestline" ||
              this.state.hotel.pms.id === "hotsoft" ? (
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    style={{
                      width: "100%",
                      backgroundColor:
                        appStore.meta._user.permissions.admin[0] !== "*"
                          ? "#D3D3D3"
                          : "none",
                    }}
                    fullWidth
                    id="outlined-basic"
                    label="Default Inventory Pickup Range (in days)"
                    variant="outlined"
                    name="pickupRange"
                    type="number"
                    onBlur={this.onBlurOfInputFields.bind(this)}
                    onChange={this.handleChange}
                    value={this.state.hotel.meta.pickupRange}
                    error={this.state.validatePickupRange}
                    helperText={this.state.validatePickupRangeMsg}
                    inputProps={{
                      maxLength: 20,
                      readOnly:
                        appStore.meta._user.permissions.admin[0] !== "*"
                          ? true
                          : false,
                    }}
                    required
                  />
                </Grid>
              ) : null}

              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  style={{
                    width: "100%",
                    backgroundColor:
                      appStore.meta._user.permissions.admin[0] !== "*"
                        ? "#D3D3D3"
                        : "none",
                  }}
                  fullWidth
                  id="outlined-basic"
                  label="Default OTA Pickup Range (in days)"
                  variant="outlined"
                  name="otaPickupRange"
                  type="number"
                  onBlur={this.onBlurOfInputFields.bind(this)}
                  onChange={this.handleChange}
                  value={this.state.hotel.meta.otaPickupRange}
                  error={this.state.validateOtaPickupRange}
                  helperText={this.state.validateOtaPickupRangeMsg}
                  inputProps={{
                    maxLength: 20,
                    readOnly:
                      appStore.meta._user.permissions.admin[0] !== "*"
                        ? true
                        : false,
                  }}
                  disabled={
                    this.state.hotel.meta.isSyncOn
                      ? this.state.hotel.meta.isSyncOn
                      : this.state.disableOTAPickup
                  }
                  required
                />
              </Grid>
              {(this.state.hotel.cm.id === "guestline" ||
                this.state.hotel.pms.id === "hotsoft") && (
                <div className="syncDiv">
                  <input
                    type="checkbox"
                    className="syncCheckBox"
                    name="isSyncOn"
                    checked={
                      this.state.hotel.meta.isSyncOn
                        ? this.state.hotel.meta.isSyncOn
                        : this.state.isSyncOn
                    }
                    onChange={this.handleChange}
                    disabled={
                      appStore.meta._user.permissions.admin[0] !== "*"
                        ? true
                        : false
                    }
                  />
                  <label className="synclabelBox">
                    Synchronize date ranges
                  </label>
                </div>
              )}
            </Grid>
          </div>
          <br />

          <SectionTitle {...{ value: "Channel Manager Settings" }} />
          <br />
          <div style={{ flexGrow: "1" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  style={{ width: "100%", backgroundColor: "#D3D3D3" }}
                  fullWidth
                  select
                  label="CM Provider"
                  variant="outlined"
                  name="channelManager"
                  onChange={this.handleChange}
                  value={this.state.hotel.cm.id}
                  inputProps={{ readOnly: true }}
                >
                  <option value="siteMinder">SiteMinder</option>
                  <option value="channelRush">Channel Rush</option>
                  <option value="guestline">Guestline</option>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={4}></Grid>
              <Grid item xs={12} sm={6} md={4}></Grid>

              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  value={this.state.hotel.meta.isPublishingInventory}
                  style={{
                    width: "calc(100% - 30px)",
                    backgroundColor:
                      this.state.hotel.pms.inventoryType === "live" &&
                      this.state.hotel.pms.id !== "none"
                        ? "#D3D3D3"
                        : this.state.hotel.cm.id === "guestline"
                          ? "#D3D3D3"
                          : appStore.meta._user.permissions.admin[0] !== "*"
                            ? "#D3D3D3"
                            : "none",
                  }}
                  fullWidth
                  select
                  label="Publish Inventory"
                  variant="outlined"
                  name="isPublishingInventory"
                  onChange={this.handleChange}
                  inputProps={{
                    readOnly:
                      this.state.hotel.pms.inventoryType === "live" &&
                      this.state.hotel.pms.id !== "none"
                        ? true
                        : this.state.hotel.cm.id === "guestline"
                          ? true
                          : appStore.meta._user.permissions.admin[0] !== "*"
                            ? true
                            : false,
                  }}
                >
                  <option value="true">Enabled</option>
                  <option value="false">Disabled</option>
                </TextField>
                <Tooltip
                  title="If enabled, Revanista will allow to publish availability to Channel Manager. If disabled, property's PMS should be sending availability to Channel Manager."
                  arrow
                  placement="right"
                >
                  <InfoIcon
                    onMouseOver={this.handleOnMouseOver}
                    onMouseOut={this.handleOnMouseOut}
                    style={{
                      color: this.state.hover ? "blue" : "",
                      marginTop: "14px",
                      marginLeft: "5px",
                      float: "none",
                    }}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={6} md={4}></Grid>
              <Grid item xs={12} sm={6} md={4}></Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  style={{
                    width: "100%",
                    backgroundColor:
                      this.state.setGuestline === true &&
                      appStore.meta._user.permissions.admin[0] == "*"
                        ? "#ffffff"
                        : "#D3D3D3",
                  }}
                  fullWidth
                  id="outlined-basic"
                  label="Hotel Code"
                  variant="outlined"
                  name="cmHotelCode"
                  onChange={this.handleChange}
                  onBlur={this.onBlurOfInputFields.bind(this)}
                  value={this.state.hotel.cm.APISettings.hotelId}
                  error={this.state.validateCmHotelCode}
                  helperText={this.state.validateCmHotelCodeMsg}
                  inputProps={{
                    maxLength: 20,
                    readOnly:
                      this.state.setGuestline === true &&
                      appStore.meta._user.permissions.admin[0] == "*"
                        ? false
                        : true,
                  }}
                  required
                />
              </Grid>

              {this.state.setGuestline === false ? (
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    style={{ width: "100%", backgroundColor: "#D3D3D3" }}
                    fullWidth
                    id="outlined-basic"
                    label="User Name"
                    variant="outlined"
                    name="cmUserId"
                    onBlur={this.onBlurOfInputFields.bind(this)}
                    onChange={this.handleChange}
                    value={this.state.hotel.cm.credentials.userId}
                    error={this.state.validateUserName}
                    helperText={this.state.validateUserNameMsg}
                    inputProps={{ maxLength: 20, readOnly: true }}
                    required
                  />
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      style={{
                        width: "100%",
                        backgroundColor:
                          appStore.meta._user.permissions.admin[0] !== "*"
                            ? "#D3D3D3"
                            : "none",
                      }}
                      fullWidth
                      id="outlined-basic"
                      label="Base Rate Flex Table"
                      variant="outlined"
                      name="flexTable"
                      onBlur={this.onBlurOfInputFields.bind(this)}
                      onChange={this.handleChange}
                      value={this.state.hotel.cm.APISettings.flexTable}
                      error={this.state.validateFelexTable}
                      helperText={this.state.validateFelexTableMsg}
                      inputProps={{
                        maxLength: 20,
                        readOnly:
                          appStore.meta._user.permissions.admin[0] !== "*"
                            ? true
                            : false,
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      style={{
                        width: "100%",
                        backgroundColor:
                          appStore.meta._user.permissions.admin[0] !== "*"
                            ? "#D3D3D3"
                            : "none",
                      }}
                      fullWidth
                      id="outlined-basic"
                      label="OWS Flex Table"
                      variant="outlined"
                      name="OWSflexTable"
                      onBlur={this.onBlurOfInputFields.bind(this)}
                      onChange={this.handleChange}
                      value={this.state.hotel.cm.APISettings.OWSflexTable}
                      error={this.state.validateOwsFelexTable}
                      helperText={this.state.validateOwsFelexTableMsg}
                      inputProps={{
                        maxLength: 20,
                        readOnly:
                          appStore.meta._user.permissions.admin[0] !== "*"
                            ? true
                            : false,
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}></Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      value={this.state.hotel.cm.APISettings.BRflexTableRate}
                      style={{
                        width: "100%",
                        backgroundColor:
                          appStore.meta._user.permissions.admin[0] !== "*"
                            ? "#D3D3D3"
                            : "none",
                      }}
                      fullWidth
                      id="outlined"
                      select
                      label="BR Flex Table Rate"
                      variant="outlined"
                      name="BRflexTableRate"
                      onChange={this.handleChange}
                      inputProps={{
                        readOnly:
                          appStore.meta._user.permissions.admin[0] !== "*"
                            ? true
                            : false,
                      }}
                    >
                      {Object.values(this.state.hotel.ratePlans).map(
                        (rate: any) => {
                          return (
                            rate.isOTAOnly == true && (
                              <option value={rate.id}>{rate.id}</option>
                            )
                          );
                        },
                      )}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      value={this.state.hotel.cm.APISettings.OWSflexTableRate}
                      style={{
                        width: "100%",
                        backgroundColor:
                          appStore.meta._user.permissions.admin[0] !== "*"
                            ? "#D3D3D3"
                            : "none",
                      }}
                      fullWidth
                      id="outlined"
                      select
                      label="OWS Flex Table Rate"
                      variant="outlined"
                      name="OWSflexTableRate"
                      onChange={this.handleChange}
                      inputProps={{
                        readOnly:
                          appStore.meta._user.permissions.admin[0] !== "*"
                            ? true
                            : false,
                      }}
                    >
                      {Object.values(this.state.hotel.ratePlans).map(
                        (rate: any, index: number) => {
                          return (
                            rate.modeOfSale === "OWS" && (
                              <option value={rate.id}>{rate.id}</option>
                            )
                          );
                        },
                      )}
                    </TextField>
                  </Grid>
                </>
              )}
            </Grid>
          </div>
          <br />

          <SectionTitle {...{ value: "PMS Settings" }} />
          <br />
          <div style={{ flexGrow: "1" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  value={this.state.hotel.pms.id}
                  style={{
                    width: "100%",
                    backgroundColor:
                      this.state.hotel.cm.id === "guestline" ||
                      appStore.meta._user.permissions.admin[0] !== "*"
                        ? "#D3D3D3"
                        : "none",
                  }}
                  fullWidth
                  select
                  label="PMS Provider"
                  variant="outlined"
                  name="pmsProvider"
                  onChange={this.handleChange}
                  inputProps={{
                    readOnly:
                      this.state.hotel.cm.id === "guestline" ||
                      appStore.meta._user.permissions.admin[0] !== "*"
                        ? true
                        : false,
                  }}
                >
                  <option value="hotsoft">HotSoft</option>
                  <option value="guestline">Guestline</option>
                  <option value="none">None</option>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={4}></Grid>
              <Grid item xs={12} sm={6} md={4}></Grid>

              {this.state.hotel.pms.id !== "none" && (
                <>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      value={this.state.hotel.pms.inventoryType}
                      style={{
                        width: "100%",
                        backgroundColor:
                          this.state.hotel.cm.id === "guestline" ||
                          appStore.meta._user.permissions.admin[0] !== "*"
                            ? "#D3D3D3"
                            : "none",
                      }}
                      fullWidth
                      select
                      label="Inventory Type"
                      variant="outlined"
                      name="inventoryType"
                      onChange={this.handleChange}
                      inputProps={{
                        readOnly:
                          this.state.hotel.cm.id === "guestline" ||
                          appStore.meta._user.permissions.admin[0] !== "*"
                            ? true
                            : false,
                      }}
                    >
                      <option value="manual">Manual</option>
                      <option value="live">Live</option>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}></Grid>
                  <Grid item xs={12} sm={6} md={4}></Grid>
                </>
              )}

              {this.state.hotel.pms.id === "hotsoft" && (
                <>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      style={{
                        width: "100%",
                        backgroundColor:
                          appStore.meta._user.permissions.admin[0] !== "*"
                            ? "#D3D3D3"
                            : "none",
                      }}
                      fullWidth
                      id="outlined-basic"
                      label="Hotel Code"
                      variant="outlined"
                      name="pmsHotelCode"
                      onChange={this.handleChange}
                      onBlur={this.onBlurOfInputFields.bind(this)}
                      value={this.state.hotel.pms.apiSettings.hotelCode}
                      error={this.state.validatePmsHotelCode}
                      helperText={this.state.validatePmsHotelCodeMsg}
                      inputProps={{
                        maxLength: 20,
                        readOnly:
                          appStore.meta._user.permissions.admin[0] !== "*"
                            ? true
                            : false,
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      style={{
                        width: "100%",
                        backgroundColor:
                          appStore.meta._user.permissions.admin[0] !== "*"
                            ? "#D3D3D3"
                            : "none",
                      }}
                      fullWidth
                      id="outlined-basic"
                      label="URL Endpoint"
                      variant="outlined"
                      name="urlEndpoint"
                      onBlur={this.onBlurOfInputFields.bind(this)}
                      onChange={this.handleChange}
                      value={this.state.hotel.pms.apiSettings.urlEndpoint}
                      error={this.state.validatePmsUrlEndpoint}
                      helperText={this.state.validatePmsUrlEndpointMsg}
                      inputProps={{
                        maxLength: 100,
                        readOnly:
                          appStore.meta._user.permissions.admin[0] !== "*"
                            ? true
                            : false,
                      }}
                      required
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </div>
          <br />

          <SectionTitle {...{ value: "OWS Differential Settings" }} />
          <br />
          {typeof this.state.hotel.cm.channelConfig.ows != "undefined" && (
            <div style={{ flexGrow: "1" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    style={{
                      backgroundColor:
                        appStore.meta._user.permissions.admin[0] !== "*"
                          ? "#D3D3D3"
                          : "none",
                    }}
                    id="outlined-basic"
                    label="Default Label"
                    variant="outlined"
                    name="OWSLabel"
                    inputProps={{
                      maxLength: 30,
                      readOnly:
                        appStore.meta._user.permissions.admin[0] !== "*"
                          ? true
                          : false,
                    }}
                    onChange={this.handleChange}
                    value={
                      this.state.hotel.cm.channelConfig.ows.label || "Default"
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    style={{
                      backgroundColor:
                        appStore.meta._user.permissions.admin[0] !== "*"
                          ? "#D3D3D3"
                          : "none",
                    }}
                    id="outlined-basic"
                    label="Default Formula"
                    variant="outlined"
                    name="OWSFormula"
                    inputProps={{
                      maxLength: 20,
                      readOnly:
                        appStore.meta._user.permissions.admin[0] !== "*"
                          ? true
                          : false,
                    }}
                    onChange={this.handleChange}
                    value={this.state.hotel.cm.channelConfig.ows.formula}
                    onBlur={this.onBlurOfInputFields.bind(this)}
                    error={this.state.validateFormula}
                    helperText={this.state.validateFormulaMsg}
                    required
                  />
                </Grid>
              </Grid>
            </div>
          )}
          <br />
          {this.state.hotel.cm.channelConfig &&
            typeof this.state.hotel.cm.channelConfig.ows != "undefined" &&
            this.state.hotel.cm.channelConfig.ows && (
              <Fragment>
                <div className="form-check form-check-inline">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          this.state.hotel.cm.channelConfig &&
                          this.state.hotel.cm.channelConfig.ows &&
                          typeof this.state.hotel.cm.channelConfig.ows !=
                            "undefined" &&
                          typeof this.state.hotel.cm.channelConfig.ows
                            .seasonnalFormula != "undefined" &&
                          this.state.hotel.cm.channelConfig.ows.seasonnalFormula
                            ? true
                            : false
                        }
                        onChange={this.handleChange}
                        name="seasonnalOWS"
                        color="primary"
                        disabled={
                          appStore.meta._user.permissions.admin[0] !== "*"
                            ? true
                            : false
                        }
                      />
                    }
                    label="OWS Overrides"
                  />
                </div>
              </Fragment>
            )}
          {this.state.hotel.cm.channelConfig &&
            typeof this.state.hotel.cm.channelConfig.ows != "undefined" &&
            typeof this.state.hotel.cm.channelConfig.ows.seasonnalFormula !=
              "undefined" &&
            this.state.hotel.cm.channelConfig.ows.seasonnalFormula && (
              <FormulaOverides
                hotel={this.state.hotel}
                key={this.state.currentSetIndex}
                onUpdate={this.onHotelUpdate}
                deleteFormula={this.state.deleteFormula}
                isEdited={this.state.onSave}
                isAdmin={appStore.meta._user.permissions}
              />
            )}
          <br />
          {appStore.meta._user.permissions.admin[0] !== "*" ? (
            <div></div>
          ) : (
            <div
              style={{
                float: "right",
                marginBottom: "5vh",
              }}
            >
              <Button
                onClick={this.cancelChanges}
                color="secondary"
                variant="contained"
                style={{
                  backgroundColor: "#808080",
                  color: "#ffffff",
                  marginRight: "10px",
                  marginTop: "10px",
                }}
              >
                Cancel Changes
              </Button>
              <Button
                onClick={this.saveConfig}
                color="primary"
                variant="contained"
                style={{
                  backgroundColor: "#ce0e29",
                  color: "#ffffff",
                  marginRight: "10px",
                  marginTop: "10px",
                }}
              >
                Save Changes
              </Button>
            </div>
          )}
        </div>

        <Popup
          isPopupOpen={this.state.openRewardsPopup}
          title="Disabling Rewards"
          content={[
            "Are you sure you want to disable rewards?",
            "● The rewards rate plans in channel manager will not be automatically removed",
          ]}
          action={() => {
            this.saveConfigAfterValidation();
          }}
          actionName="Disable Rewards"
          cancelAction={this.closeRewardsPopup}
          cancelName="Cancel"
        />
      </MuiThemeProvider>
    );
  }
}

export default withRouter(NewHotelConfig);
