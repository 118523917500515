import { CellCoords } from "../../../../models/yield.models";
import { HotTable } from "@handsontable-pro/react";

interface ContextMenuItem {
  name: string;
  key?: string;
  callback?: (
    name: string,
    selection: Array<{ start: CellCoords; end: CellCoords }>,
    clickEvent: any,
  ) => void;
  disabled: () => boolean;
  hidden: () => boolean;
  isCommand?: boolean;
  submenu?: any;
}

export function getItems(
  this: any,
  ys: any,
  instance: any,
  isDisabled: boolean | null,
): {
  [item: string]: ContextMenuItem;
} {
  if (isDisabled) {
    return {};
  }
  // horrible hack for production

  let items = {
    CLOSE_DAY: getDaySubMenuItem(
      ys,
      "apply",
      "Close",
      isDisabled,
      "stop_sell" as RestrictionTypeKeys,
    ),
    OPEN_DAY: getDaySubMenuItem(
      ys,
      "remove",
      "Open",
      isDisabled,
      "stop_sell" as RestrictionTypeKeys,
    ),
    MINLOS2: getMinDayMenuItem(ys, "apply", "Apply min LOS 2", isDisabled),
    REMOVEMINLOS: getMinDayMenuItem(ys, "remove", "Remove min LOS", isDisabled),
  };

  return items;
}

function getDaySubMenuItem(
  ys: any,
  type: string,
  name: string,
  isDisabled: boolean | null,
  restrictionType: RestrictionTypeKeys,
): ContextMenuItem {
  let contextMenu: ContextMenuItem = {
    name,
    key: `${type}_day`,
    disabled() {
      return false;
    },
    hidden() {
      if (isDisabled) {
        return true;
      }
      const hotInstance: HotTable["hotInstance"] = this;
      const selectedLast = hotInstance.getSelectedLast();
      const selectedRangeLast = hotInstance.getSelectedRangeLast();

      if (!selectedLast || !selectedRangeLast) {
        return true;
      }

      let col = selectedLast[1];
      if (col === 0) {
        return true;
      }
      const { row } = selectedRangeLast.highlight;
      return row !== 1;
    },
    callback: (itemName: any, selection: any) => {},
  };
  let items: any[] = [];
  items.push({
    key: `${type}_day:all`,
    name: "All Rooms",
    callback: (itemName: any, selection: any) => {
      if (
        !selection ||
        !Array.isArray(selection) ||
        !selection[0] ||
        !selection[0].start
      ) {
        console.error("Invalid selection:", selection);
        return;
      }
      let col = selection[0].start.col;
      switch (restrictionType) {
        case "stop_sell" as RestrictionTypeKeys:
          ys.closeOrOpenDay(col, type, "all");
          break;
      }
    },
  });
  items.push({
    key: `${type}_day:ota`,
    name: "OTA Rates",
    callback: (itemName: any, selection: any) => {
      if (
        !selection ||
        !Array.isArray(selection) ||
        !selection[0] ||
        !selection[0].start
      ) {
        console.error("Invalid selection:", selection);
        return;
      }
      let col = selection[0].start.col;
      switch (restrictionType) {
        case "stop_sell" as RestrictionTypeKeys:
          ys.closeOrOpenDay(col, type, "ota");
          break;
      }
    },
  });
  items.push({
    key: `${type}_day:ows`,
    name: "OWS Rates",
    callback: (itemName: any, selection: any) => {
      if (
        !selection ||
        !Array.isArray(selection) ||
        !selection[0] ||
        !selection[0].start
      ) {
        console.error("Invalid selection:", selection);
        return;
      }
      let col = selection[0].start.col;
      switch (restrictionType) {
        case "stop_sell" as RestrictionTypeKeys:
          ys.closeOrOpenDay(col, type, "ows");
          break;
      }
    },
  });
  items.push({
    key: `${type}_day:gds`,
    name: "GDS Rates",
    callback: (itemName: any, selection: any) => {
      if (
        !selection ||
        !Array.isArray(selection) ||
        !selection[0] ||
        !selection[0].start
      ) {
        console.error("Invalid selection:", selection);
        return;
      }
      let col = selection[0].start.col;
      switch (restrictionType) {
        case "stop_sell" as RestrictionTypeKeys:
          ys.closeOrOpenDay(col, type, "gds");
          break;
      }
    },
  });
  contextMenu.submenu = { items };

  return contextMenu;
}

function getMinDayMenuItem(
  ys: any,
  type: string,
  name: string,
  isDisabled: boolean | null,
): ContextMenuItem {
  return {
    name: name,
    disabled() {
      //not so necessary since we hide all disabled items!
      return false;
    },
    hidden() {
      if (isDisabled) {
        return true;
      }
      const hotInstance: HotTable["hotInstance"] = this;
      if (hotInstance.getSelectedLast()[1] === 0) {
        return true;
      }
      const { row } = hotInstance.getSelectedRangeLast().highlight;
      return row !== 1;
    },
    callback: (itemName: any, selection: any) => {
      let col = selection[0].start.col;
      ys.minDayForDay(col, type);
    },
  };
}
