import {
  IFetchCalendarEventsRequest,
  IHotelConf,
  IRatePlanConf,
  IRoomType,
} from "./../../../models/api.models";
import { YieldApi } from "../../../api/yield";
import { UserApi } from "../../../api/user";
import { OtaApi } from "../../../api/ota";
import { events as gnEvent } from "gn-shared";

const revanista: any = {
  files: {
    getUploadLink: async (
      hotelId: any,
      contentType: string,
      fileExtension: string,
      filename: string,
    ) => {
      try {
        return await YieldApi.getUploadLink({
          hotelId,
          fileExtension,
          contentType,
          filename,
        });
      } catch (err) {
        throw err;
      }
    },
    getDownloadLink: async (
      hotelId: any,
      filename: string,
      overiddenFileName: string,
    ) => {
      try {
        return await YieldApi.getDownloadLink({
          hotelId,
          filename,
          overiddenFileName,
        });
      } catch (err) {
        throw err;
      }
    },

    upload: async (url: string, contentType: string, data: any) => {
      try {
        return await YieldApi.uploadFile({ url, contentType, data });
      } catch (err) {
        throw err;
      }
    },

    delete: async (hotelId: any, filename: string) => {
      try {
        return await YieldApi.deleteFile({ hotelId, filename });
      } catch (err) {
        throw err;
      }
    },
  },
  hotel: {
    createUser: async (
      hotelIds: number[],
      userEmail: string,
      isAdmin: boolean,
    ) => {
      try {
        const res = await UserApi.createHotelUser({
          hotelIds,
          userEmail,
          isAdmin,
        });
        return res.userCreated;
      } catch (err) {
        throw err;
      }
    },

    deleteUser: async (hotelIds: number[], userEmail: string) => {
      try {
        return await UserApi.deleteHotelUser({ hotelIds, userEmail });
      } catch (err) {
        throw err;
      }
    },
    resetUserPassword: async (hotelIds: number[], userEmail: string) => {
      try {
        return await UserApi.resetUserPassword({ hotelIds, userEmail });
      } catch (err) {
        throw err;
      }
    },
    listUser: async (hotelId: number) => {
      try {
        return await UserApi.listHotelUser(hotelId);
      } catch (err) {
        throw err;
      }
    },
    updateMonthlyPopupValue: async (
      userEmail: any,
      customPermission: any,
      hotelIds: number,
    ) => {
      try {
        return await UserApi.updateMonthlyPopupValue({
          userEmail,
          customPermission,
          hotelIds,
        });
      } catch (err) {
        throw err;
      }
    },

    remindMeLater: async (
      userEmail: any,
      customPermission: any,
      hotelIds: number,
    ) => {
      try {
        return await UserApi.remindMeLater({
          userEmail,
          customPermission,
          hotelIds,
        });
      } catch (err) {
        throw err;
      }
    },
    getAttribute: async (token: string) => {
      try {
        return await UserApi.getAttribute(token);
      } catch (err) {
        throw err;
      }
    },
    getCompSetList: async (hotelId: number) => {
      try {
        const res = await OtaApi.getOtaHotelCompList(hotelId);
        return res;
      } catch (err) {
        throw err;
      }
    },
    getExecSummaryItems: async (hotelId: number) => {
      try {
        const res = await YieldApi.getExecSummaryItems(hotelId);
        return JSON.parse(res.Payload);
      } catch (err) {
        throw err;
      }
    },
    putExecSummaryItem: async (item: any) => {
      try {
        return await YieldApi.putExecSummaryItem(item);
      } catch (err) {
        throw err;
      }
    },
    editSubscriptionList: async (hotelId: number, contactEmails: string[]) => {
      try {
        return await YieldApi.editSubscriptionList({ hotelId, contactEmails });
      } catch (err) {
        throw err;
      }
    },
    deleteRateplan: async (id: string, hotelId: number) => {
      try {
        return await YieldApi.deleteRateplan({ id, hotelId });
      } catch (err) {
        throw err;
      }
    },
    deleteRoomType: async (rtId: string, hotelId: number) => {
      try {
        return await YieldApi.deleteRoomType({ rtId, hotelId });
      } catch (err) {
        throw err;
      }
    },
    writeRoomType: async (rt: any, hotelId: number) => {
      const body = rt;
      body.hotelId = hotelId;
      try {
        return await YieldApi.writeRoomType(body as IRoomType);
      } catch (err) {
        throw err;
      }
    },
    writeRtSortOrder: async (rt: any, hotelId: number) => {
      const body = rt;
      body.hotelId = hotelId;
      try {
        return await YieldApi.writeRtSortOrder(body as IRoomType);
      } catch (err) {
        throw err;
      }
    },
    writeRpSortOrder: async (rt: any, hotelId: number) => {
      const body = rt;
      body.hotelId = hotelId;
      try {
        return await YieldApi.writeRpSortOrder(body as IRoomType);
      } catch (err) {
        throw err;
      }
    },
    writeRateplan: async (rp: any, hotelId: number) => {
      const body = rp;
      body.hotelId = hotelId;
      try {
        return await YieldApi.writeRateplan(body as IRatePlanConf);
      } catch (err) {
        throw err;
      }
    },
    addNewHotel: async (body: any) => {
      try {
        return await YieldApi.addNewHotel(body as IHotelConf);
      } catch (err) {
        throw err;
      }
    },
  },
  yieldsheet: {
    notify: {
      event: async (hotelId: any, event: any) => {
        try {
          return await YieldApi.notify({
            hotelId,
            type: gnEvent.notificationType.event,
            event,
          });
        } catch (err) {
          throw err;
        }
      },
      suggestedBR: async (hotelId: any) => {
        try {
          return await YieldApi.notify({
            hotelId,
            type: gnEvent.notificationType.suggestions,
          });
        } catch (err) {
          throw err;
        }
      },
    },
    days: {
      bulkUpdateRates: async (payload: any, props: any) => {
        try {
          const res = await YieldApi.bulkUpdateRates(payload);
          return res;
        } catch (err) {
          throw err;
        }
      },
      bulkUpdateManualRates: async (payload: any, props: any) => {
        try {
          const res = await YieldApi.bulkUpdateManualRates(payload);
          return res;
        } catch (err) {
          throw err;
        }
      },
      bulkUpdateRewards: async (payload: any, props: any) => {
        try {
          const res = await YieldApi.bulkUpdateRewards(payload);
          return res;
        } catch (err) {
          throw err;
        }
      },
      list: async (activeHotelId: any, dateFrom: any, dateTo: any) => {
        try {
          const res = await YieldApi.list(activeHotelId, dateFrom, dateTo);
          return JSON.parse(res.Payload);
        } catch (err) {
          throw err;
        }
      },
      fetchCalendarEvents: async (request: IFetchCalendarEventsRequest) => {
        try {
          return await YieldApi.fetchCalendarEvents(request);
        } catch (err) {
          throw err;
        }
      },
      updateLeftToSell: async (payload: any, props: any) => {
        props.appStore.requests.setYield("updateLeftToSell", "fetching");
        try {
          const res = await YieldApi.updateLeftToSell(payload);
          props.appStore.requests.setYield("updateLeftToSell", "success");
          return res;
        } catch (err) {
          props.appStore.requests.setYield("updateLeftToSell", "error");
          throw err;
        }
      },
      updateSuggestedBaseRate: async (payload: any, props: any) => {
        props.appStore.requests.setYield("updateSuggestedBaseRate", "fetching");
        try {
          const res = await YieldApi.updateSuggestedBaseRate(payload);
          props.appStore.requests.setYield(
            "updateSuggestedBaseRate",
            "success",
          );
          return res;
        } catch (err) {
          props.appStore.requests.setYield("updateSuggestedBaseRate", "error");
          throw err;
        }
      },
      writeManualRate: async (payload: any, props: any) => {
        try {
          return await YieldApi.writeManualRate(payload);
        } catch (err) {
          throw err;
        }
      },
    },
  },
};

const YieldSheetService = {
  revanista: revanista,
};

export { YieldSheetService };
