import { utils } from "gn-shared";

export function validateRTFormula(formula: string) {
  if (parseFloat(formula)) {
    return false;
  } else {
    try {
      utils.evaluate(formula, { BR: 100 });
      return true;
    } catch (e) {
      return false;
    }
  }
}

export function validateRPFormula(formula: string) {
  if (parseFloat(formula)) {
    return false;
  } else {
    try {
      utils.evaluate(formula, { RR: 100, OCC: 1, CHILDOCC: 1 });
      return true;
    } catch (e) {
      return false;
    }
  }
}
